import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import CONSTANTS from "../constants"

import classic from "../images/classic.jpg"
import full from "../images/full.jpg"

const title = "La Pagaie d'Aure | rafting St Lary"
const description = "Rafting dans les Pyrénées à Saint-Lary-Soulan, sur la Neste d'Aure. 100% fun et dépaysant !"
const keywords = "rafting, saint-lary, st lary, pyrénées"

const date = new Date()
date.setMonth(11)
date.setDate(31)
const endOfYear = date.toISOString().substr(0, 10) // YYYY-MM-DD

const structuredContent = {
  "@context": "http://schema.org",
  "@type": "ItemList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      item: {
        "@type": "product",
        name: "Descente classique",
        brand: {
          "@type": "Thing",
          name: "La Pagaie d'Aure",
        },
        image: "https://" + CONSTANTS.hostname + classic,
        description:
          "Venez tester le rafting en vallée d'Aure, une activité de montagne accessible, ludique, aquatique et en pleine nature. 1h30 à 1h45 de navigation et péripéties à bord de ce gros bateau gonflable pour un dépaysement total ! En famille ou entre amis, venez faire un tour de rafting sur l’artère de la vallée, la belle Neste d’Aure.",
        offers: {
          "@type": "Offer",
          priceCurrency: "EUR",
          price: CONSTANTS.prices.classic,
          availability: "http://schema.org/InStock",
          seller: {
            "@type": "Organization",
            name: "La Pagaie d'Aure",
          },
          priceValidUntil: endOfYear,
          sku: "classic",
        },
        url: "https://www.lapagaiedaure.com/#classic",
      },
    },
    {
      "@type": "ListItem",
      position: 2,
      item: {
        "@type": "product",
        name: "Descente intégrale",
        brand: {
          "@type": "Thing",
          name: "La Pagaie d'Aure",
        },
        image: "https://" + CONSTANTS.hostname + full,
        description:
          "Pour l’après midi, si le niveau d’eau le permet, vous pouvez choisir de faire environ 50 minutes supplémentaires de descente pure, sur une portion inexploitée de cette superbe rivière !",
        offers: {
          "@type": "Offer",
          priceCurrency: "EUR",
          price: CONSTANTS.prices.full,
          availability: "http://schema.org/InStock",
          seller: {
            "@type": "Organization",
            name: "La Pagaie d'Aure",
          },
          priceValidUntil: endOfYear,
          sku: "full",
        },
        url: "https://www.lapagaiedaure.com/#full",
      },
    },
  ],
}

const SEO = ({ robots }) => (
  <Helmet htmlAttributes={{ lang: "fr" }}>
    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta name="description" property="og:description" content={description} />
    <meta property="og:type" content="website" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:description" content={description} />
    <meta name="robots" content={robots} />
    <meta name="keywords" content={keywords} />
    <script type="application/ld+json">{JSON.stringify(structuredContent)}</script>
  </Helmet>
)

SEO.defaultProps = {
  robots: "noindex, nofollow",
}

SEO.propTypes = {
  robots: PropTypes.string,
}

export default SEO
