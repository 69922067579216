import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { MdSend } from "react-icons/md"

import Input from "./Input"

import "./Contact.css"

import api from "../helpers/api"

class Contact extends Component {
  static propTypes = {
    // Redux
    lang: PropTypes.string.isRequired,
    // Parent
    className: PropTypes.string.isRequired,
  }

  state = {
    name: "",
    email: "",
    message: "",
    error: null,
    sending: false,
    sent: false,
  }

  handleInput = (value, name) => {
    this.setState({
      [name]: value,
    })
  }

  handleSend = (event) => {
    event.preventDefault()

    const { name, email, message } = this.state

    if (!(name && email && message)) {
      return
    }

    this.setState({
      sending: true,
    })

    api
      .post("contact", {
        name,
        email,
        message,
        lang: this.props.lang,
      })
      .then(() => {
        this.setState({
          sending: false,
          sent: true,
        })
      })
      .catch((error) => {
        this.setState({
          sending: false,
          error: error.message,
        })
      })
  }

  render() {
    const { name, email, message, error, sending, sent } = this.state
    let className = this.props.className

    className += " contactform"

    if (sent) {
      className += " sent"
      return (
        <div className={className}>
          <FormattedMessage id="sent" />
        </div>
      )
    } else {
      const emptyForm = !(name && email && message)

      return (
        <form onSubmit={this.handleSend} className={className}>
          <Input name="name" value={name} onChange={this.handleInput} />
          <Input type="email" name="email" value={email} onChange={this.handleInput} />
          <Input multiline name="message" value={message} onChange={this.handleInput} />
          {error && (
            <div className="error">
              <FormattedMessage id={"error_" + error} />
            </div>
          )}
          <button type="submit" className={emptyForm || sending ? "disabled" : ""}>
            <FormattedMessage id="send" /> <MdSend color="#008FCA" size={22} />
          </button>
        </form>
      )
    }
  }
}

const mapStateToProps = (state) => ({
  lang: state.app.lang,
})

export default connect(mapStateToProps)(Contact)
