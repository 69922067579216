import jump from "jump.js"

const SMOOTH_DURATION = 500

let scrolling

const end = () => {
  scrolling = false
}

const scroll = (to, immediate) => {
  if (scrolling) {
    return
  }
  if (!immediate) {
    scrolling = true
    setTimeout(end, SMOOTH_DURATION)
  }
  const target = document.querySelector(to)
  if (target) {
    jump(target, {
      duration: immediate ? 0 : SMOOTH_DURATION,
    })
  }
}

export default scroll
