import React, { Component } from "react"
import PropTypes from "prop-types"
import { injectIntl, intlShape } from "react-intl"

import "./Input.css"

class Input extends Component {
  static propTypes = {
    // react-intl
    intl: intlShape.isRequired,
    // parent
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    multiline: PropTypes.bool,
  }

  state = {
    focus: !!this.props.value,
  }

  handleFocus = () => {
    this.setState({
      focus: true,
    })
  }

  handleBlur = () => {
    this.setState({
      focus: false,
    })
  }

  handleChange = ({ target }) => {
    const { name, value } = target
    this.props.onChange(value, name)
  }

  render() {
    const { type, multiline, intl, ...props } = this.props
    const { focus } = this.state

    const label = intl.formatMessage({ id: "form_" + props.name })

    props.onChange = this.handleChange
    props.onFocus = this.handleFocus
    props.onBlur = this.handleBlur
    props["aria-label"] = label

    let className = "input"
    if (focus || props.value) {
      className += " focus"
    }
    if (multiline) {
      className += " textarea"
    } else {
      props.type = type || "text"
    }

    return (
      <div className={className}>
        <label>{label}</label>
        {multiline ? <textarea ref="input" {...props} /> : <input ref="input" {...props} />}
      </div>
    )
  }
}

export default injectIntl(Input)
