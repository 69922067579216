import React, { Component } from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { MdKeyboardArrowDown, MdVerticalAlignTop } from "react-icons/md"

import "./index.css"

import Header from "../components/Header"
import Contact from "../components/Contact"
import SEO from "../components/SEO"
import scroll from "../helpers/scroll"

import logo from "../images/logo800.png"
import map from "../images/map.svg"

import intersport from "../images/intersport.png"
import panaurama from "../images/panaurama.png"
import saintlary from "../images/saintlary.png"
import piau from "../images/piau.png"
import enjoy from "../images/enjoy.png"

import CONSTANTS from "../constants"
CONSTANTS.phone_intl = CONSTANTS.phone.replace(/^0/, "+33").replace(/ /g, "")

const isTop = () => {
  if (typeof window === "undefined") {
    return true
  }
  return (document.scrollingElement || document.documentElement).scrollTop < window.innerHeight - 120
}

class IndexPage extends Component {
  state = {
    top: isTop(),
    widget: null,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    this.setState({
      widget: window.innerWidth > 750 ? "big" : "small",
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    const top = isTop()
    if (top !== this.state.top) {
      this.setState({
        top,
      })
    }
  }

  handleSection = (to) => () => {
    scroll("." + to)
  }

  render() {
    const topButton = (
      <button onClick={this.handleSection("home")} data-target="home" className="top" aria-hidden>
        <MdVerticalAlignTop color="white" size={30} />
      </button>
    )
    const nextButton = (to, animate) => (
      <button onClick={this.handleSection(to)} className={"next" + (animate ? " down" : "")} aria-hidden>
        <MdKeyboardArrowDown color="white" size={40} />
      </button>
    )
    const menuButton = (to) => (
      <button onClick={this.handleSection(to)}>
        <FormattedMessage id={to} />
      </button>
    )

    const { top, widget } = this.state

    return (
      <div className="iefix">
        <SEO robots="index, follow" />
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://www.jscache.com" />
          <link rel="preconnect" href="https://www.tripadvisor.com" />
          <link rel="preconnect" href="https://static.tacdn.com" />
          <link rel="preconnect" href="https://p.travelsmarter.net" />
          <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        </Helmet>
        <Header
          onLogoClick={this.handleSection}
          right={
            top ? null : (
              <Link to="/calendar/" className="headerCta">
                <FormattedMessage id="cta" />
              </Link>
            )
          }
        >
          {menuButton("classic_downhill")}
          {menuButton("full_downhill")}
          {menuButton("more")}
          {menuButton("contact")}
        </Header>
        <div className="slide home">
          <div className="welcome">
            <img src={logo} alt="La Pagaie d'Aure — Rafting Pyrénées" />
            <div>
              <FormattedMessage id="title" tagName="h1" />
              <FormattedMessage id="description" tagName="h2" />
              {widget && (
                <iframe
                  title="TripAdvisor"
                  width="100%"
                  height={widget === "big" ? 64 : 109}
                  scrolling="no"
                  id="ta"
                  src={`/tripadvisor-${widget}.html`}
                />
              )}
            </div>
          </div>
          <div className="cta waves">
            <Link to="/calendar/" className="button big">
              <FormattedMessage id="cta" />
            </Link>
          </div>
          {nextButton("classic_downhill", true)}
        </div>
        <div className="slide classic_downhill">
          <div className="bg" />
          <div className="text">
            <FormattedMessage id="classic_downhill" tagName="h2" />
            <p>
              <FormattedMessage id="classic_description" />
              <br />
              <FormattedMessage id="what_to_bring" />
            </p>
            <p>
              <FormattedMessage id="departures" />
              {" — "}
              <FormattedMessage id="duration" values={{ duration: "2h30" }} />
              <br />
              <FormattedMessage id="price" values={{ price: CONSTANTS.prices.classic }} />
            </p>
            {topButton}
          </div>
          {nextButton("full_downhill")}
        </div>
        <div className="slide full_downhill">
          <div className="bg" />
          <div className="text">
            <FormattedMessage id="full_downhill" tagName="h2" />
            <p>
              <FormattedMessage id="full_description" />
              <br />
              <FormattedMessage id="what_to_bring" />
            </p>
            <p>
              <FormattedMessage id="departure" />
              {" — "}
              <FormattedMessage id="duration" values={{ duration: "3h20" }} />
              <br />
              <FormattedMessage id="price" values={{ price: CONSTANTS.prices.full }} />
            </p>
            {topButton}
          </div>
          {nextButton("more")}
        </div>
        <div className="slide more">
          <div className="bg">
            <img src={map} alt="Descentes en rafting" />
          </div>
          <div className="text">
            <FormattedMessage id="more" tagName="h2" />
            <p>
              <FormattedMessage id="more_gear" />
              <br />
              <FormattedMessage id="more_infos" />
            </p>
            <p>
              <FormattedMessage id="instructor" />
            </p>
            <p>
              <Link to="/legal/">
                <FormattedMessage id="legal" />
              </Link>
            </p>
            {topButton}
          </div>
          {nextButton("contact")}
        </div>
        <div className="slide contact">
          <div className="infos">
            <p>Jean et Charlie</p>
            <p>
              <a href={"tel:" + CONSTANTS.phone_intl}>{CONSTANTS.phone}</a>
            </p>
            <p>
              <a href={"mailto:" + CONSTANTS.email}>{CONSTANTS.email}</a>
            </p>
          </div>
          <Contact className="waves" />
        </div>
        <div className="footer">
          <ul className="social">
            <li>
              <a href="https://www.instagram.com/lapagaiedaure/" title="Instagram" target="_blank" rel="noopener">
                <svg role="img" viewBox="0 0 24 24" fill="#E4405F">
                  <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/lapagaiedaure/" target="_blank" rel="noopener">
                <svg role="img" viewBox="0 0 24 24" fill="#1877F2" title="Facebook">
                  <path d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z" />
                </svg>
              </a>
            </li>
            <li>
              <a
                href="https://www.tripadvisor.fr/Attraction_Review-g196629-d12502505-Reviews-La_Pagaie_d_Aure-Saint_Lary_Soulan_Hautes_Pyrenees_Occitanie.html"
                target="_blank"
                rel="noopener"
              >
                <svg role="img" viewBox="0 0 24 24" fill="#34E0A1" title="Tripadvisor">
                  <path d="M12.006 4.295c-2.67 0-5.338.784-7.645 2.353H0l1.963 2.135a5.997 5.997 0 0 0 4.04 10.43 5.976 5.976 0 0 0 4.075-1.6L12 19.705l1.922-2.09a5.972 5.972 0 0 0 4.072 1.598 6 6 0 0 0 6-5.998 5.982 5.982 0 0 0-1.957-4.432L24 6.648h-4.35a13.573 13.573 0 0 0-7.644-2.353zM12 6.255c1.531 0 3.063.303 4.504.903C13.943 8.138 12 10.43 12 13.1c0-2.671-1.942-4.962-4.504-5.942A11.72 11.72 0 0 1 12 6.256zM6.002 9.157a4.059 4.059 0 1 1 0 8.118 4.059 4.059 0 0 1 0-8.118zm11.992.002a4.057 4.057 0 1 1 .003 8.115 4.057 4.057 0 0 1-.003-8.115zm-11.992 1.93a2.128 2.128 0 0 0 0 4.256 2.128 2.128 0 0 0 0-4.256zm11.992 0a2.128 2.128 0 0 0 0 4.256 2.128 2.128 0 0 0 0-4.256z" />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://g.page/lapagaiedaure" target="_blank" rel="noopener">
                <svg role="img" viewBox="0 0 24 24" fill="#4285F4" title="Google Maps">
                  <path d="M19.527 4.799c1.212 2.608.937 5.678-.405 8.173-1.101 2.047-2.744 3.74-4.098 5.614-.619.858-1.244 1.75-1.669 2.727-.141.325-.263.658-.383.992-.121.333-.224.673-.34 1.008-.109.314-.236.684-.627.687h-.007c-.466-.001-.579-.53-.695-.887-.284-.874-.581-1.713-1.019-2.525-.51-.944-1.145-1.817-1.79-2.671L19.527 4.799zM8.545 7.705l-3.959 4.707c.724 1.54 1.821 2.863 2.871 4.18.247.31.494.622.737.936l4.984-5.925-.029.01c-1.741.601-3.691-.291-4.392-1.987a3.377 3.377 0 0 1-.209-.716c-.063-.437-.077-.761-.004-1.198l.001-.007zM5.492 3.149l-.003.004c-1.947 2.466-2.281 5.88-1.117 8.77l4.785-5.689-.058-.05-3.607-3.035zM14.661.436l-3.838 4.563a.295.295 0 0 1 .027-.01c1.6-.551 3.403.15 4.22 1.626.176.319.323.683.377 1.045.068.446.085.773.012 1.22l-.003.016 3.836-4.561A8.382 8.382 0 0 0 14.67.439l-.009-.003zM9.466 5.868L14.162.285l-.047-.012A8.31 8.31 0 0 0 11.986 0a8.439 8.439 0 0 0-6.169 2.766l-.016.018 3.665 3.084z" />
                </svg>
              </a>
            </li>
          </ul>
          <h4>Partenaires :</h4>
          <ul className="partners">
            <li>
              <a href="https://www.piau-engaly.com/fr" target="_blank" rel="noopener">
                <img src={piau} alt="Piau-Engaly" loading="lazy" />
              </a>
            </li>
            <li>
              <a href="http://www.saintlary-intersport.com/" target="_blank" rel="noopener">
                <img src={intersport} alt="Intersport Saint-Lary" loading="lazy" />
              </a>
            </li>
            <li>
              <a href="https://www.saintlary.com/ete" target="_blank" rel="noopener">
                <img src={saintlary} alt="Saint-Lary Soulan" loading="lazy" />
              </a>
            </li>
            <li>
              <a href="https://www.enjoyecoledesurf-hossegor.com/" target="_blank" rel="noopener">
                <img src={enjoy} alt="Enjoy - Ecole de surf - Hossegor" />
              </a>
            </li>
            <li>
              <a href="https://www.panaurama-saintlary.com/rafting-1" target="_blank" rel="noopener">
                <img src={panaurama} alt="Panaurama Saint-Lary" loading="lazy" />
              </a>
            </li>
            <li>
              <a href="https://www.kayak.fr/Saint-Lary-Soulan.48944.guide" target="_blank" rel="noopener">
                <img src="https://www.kayak.com/news/badge/kk/tg005.png" loading="lazy" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default IndexPage
